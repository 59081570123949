<template>
  <div>
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-60 md-size-50"
      ></div>
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-40 md-size-50"
      >
        <div class="crud-add-button">
          <div>
            <router-link to="/departments/create">
              <span class="material-icons"> add_circle </span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <datatables-wrapper
      @optionsChanged="getItems"
      @updateTable="getItems"
      :loading="loading"
      :data="departments"
      :headers="headers"
      filterType="simple"
      :parameters="['name']"
      @rowClick="rowClick"
    >
    </datatables-wrapper>
  </div>
</template>

<script>
import DatatablesWrapper from "../Datatables/DatatablesWrapper.vue";
export default {
  name: "departments-crud",
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
  components: {
    DatatablesWrapper,
  },
  data() {
    return {
      headers: [
        {
          text: "Nombre del departamento",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Creado en", value: "timestamp" },
      ],
      loading: false,
      departments: {},
    };
  },
  methods: {
    getItems(options) {
      this.loading = true;
      this.$store
        .dispatch("department/getDepartments", options)
        .then((data) => (this.departments = data))
        .finally(() => (this.loading = false));
    },
    rowClick(event) {
      this.$router.push({
        name: "department",
        params: { department: event.id, tab: "details" },
      });
    },
  },
  mounted() {
    this.getItems({ page: 1 });
  },
};
</script>
<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header :data-background-color="getTheme">
          <h4 class="title">{{$t('department.departmentTableTitle')}}</h4>
        </md-card-header>
        <md-card-content>
          <departments-crud :table-header-color="getTheme"></departments-crud>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import DepartmentsCrud from "../components/Departments/DepartmentsCrud";
import { mapGetters } from "vuex";

export default {
  components: {
    DepartmentsCrud,
  },
  computed: {
    ...mapGetters(["getTheme"]),
  },
};
</script>